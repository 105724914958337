<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawerVisible"
            :wrapperClosable="false"
            direction="rtl"
            custom-class="cus-drawer"
        >
        <!-- <div class="cus_drawer_container"> -->
            <div class="cus_drawer_content">
                       <!-- ---------------------字典管理------------------- -->
            <el-form
                label-position="right"
                :model="formData"
                ref="formData"
                :rules="rules"
            >
                <el-form-item label="字典编码" prop="code">
                    <el-input
                        size="small"
                        v-model="formData.code"
                        placeholder="请输入字典编码"
                        :disabled="detail"
                    ></el-input>
                </el-form-item>
                <el-form-item label="字典名称" prop="name">
                    <el-input
                        size="small"
                        v-model="formData.name"
                        placeholder="请输入字典名称"
                        :disabled="detail"
                    ></el-input>
                </el-form-item>
                <el-form-item label="应用名称" prop="appCode">
                      <el-select
                        size="small"
                        style="width:100%"
                        v-model="formData.appCode"
                        placeholder="请选择"
                        :disabled="detail"
                    >
                        <el-option v-for="item in selectList1" :key="item.id" :label="item.name" :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
                      <el-form-item label="状态" prop="status">
                    <el-select
                        size="small"
                        style="width:100%"
                        v-model="formData.status"
                        placeholder="请选择"
                        :disabled="detail"
                    >
                        <el-option v-for="item in selectList" :key="item.val" :label="item.mean" :value="item.val"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="微服务主键标识" prop="microServiceId">
                    <el-input
                        size="small"
                        v-model="formData.microServiceId"
                        placeholder="请输入微服务主键标识"
                        :disabled="detail"
                    ></el-input>
                </el-form-item>
                <el-form-item label="字段单位" prop="unit">
                    <el-input
                        size="small"
                        v-model="formData.unit"
                        placeholder="字段单位"
                        :disabled="detail"
                    ></el-input>
                </el-form-item> -->
            </el-form>
            <!-- </div> -->
            <footer class="cus_drawer_footer">
                <el-button size="small" plain @click="cancelDicDrawer">取消</el-button>
                <el-button size="small" type="primary" v-if="!detail" @click="submitDic">提交</el-button>
            </footer>
        </div>
        </el-drawer>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import { reqDictionaryAdd, reqDictionaryUpdate } from '@/api/system/dictionary'
import { reqStytemList } from '@/api/question/safty.js'
export default {
    // 抽屉
    name: 'dicDrawer',

    props: {
        formData: { type: Object }, // 抽屉呈现信息
        callback: Function, // 回调方法--实现数据在页面表格的同步更新
        detail: { // 输入框启用/禁用
            type: Boolean,
            default: false
        },
        // 标题
        operateTitle: {
            type: String,
            default: ''
        },
        // 新增、修改切换
        temp: {
            type: Number,
            default: 0
        }
    },

    data () {
        return {
            drawerVisible: false,
            // 抽屉表单规则
            rules: {
                // 字典名称
                name: [{ required: true, message: '请输入字典编码', trigger: 'blur' }],
                // 字典编码
                code: [{ required: true, message: '请输入字典编码', trigger: 'blur' }]

            },
            title: '',
            selectList: [],
            selectList1: []

        }
    },

    created () {
        this.$utils.getDicData('SYSTEM_NORMAL_STATUS')
            .then((result) => {
                // console.log(result)
                this.selectList = result
            })
        reqStytemList().then((res) => {
            // console.log(res)
            this.selectList1 = res.data.records
        })
    },

    watch: {
        operateTitle (v) {
            if (v === 'add') {
                this.title = '新增'
            } else if (v === 'detail') {
                this.title = '详情'
            } else if (v === 'update') {
                this.title = '修改'
            }
        }

    },

    methods: {
        // 关闭抽屉时，清空信息
        clear () {
            for (const i in this.formData) {
                this.formData[i] = ''
            }
            this.formData.status = '1'
        },

        // 抽屉--取消
        cancelDicDrawer () {
            this.clear()
            this.$refs.formData.clearValidate()
            this.drawerVisible = false
        },

        // 提交校验
        submitDic () {
            this.$refs.formData.validate(valid => {
                if (valid) {
                    if (this.temp === 0) {
                        // console.log(this.formData)
                        // 新增
                        this.formData.createTime = dayjs(Date()).format(
                            'YYYY-MM-DD HH:mm:ss'
                        )
                        reqDictionaryAdd(this.formData)
                            .then(result => { this.$emit('callback') })
                    } else {
                        // 修改
                        this.formData.updateTime = dayjs(Date()).format('YYYY-MM-DD HH:mm:ss')
                        reqDictionaryUpdate(this.formData)
                            .then(result => { this.$emit('callback') })
                    }
                    this.drawerVisible = false
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .cus-over{
    overflow: auto;
}
</style>
