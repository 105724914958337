<template>
    <div>
        <el-container>
            <el-main>
                <TableWrap @callback="handlePagination" :total="total">
                     <template slot="tablesearch">
                        <SearchWrap :searchOptions="searchOptions" inline @callback="handleSearchCallback"></SearchWrap>
                    </template>
                    <template slot="tableOperate">
                        <el-button
                            @click="addDic"
                            type="primary"
                            size="small"
                            >新增</el-button
                        >
                        <el-button
                            type="primary"
                            size="small"
                            @click="updateDic"
                            >修改</el-button
                        >
                    </template>
                    <el-table
                        :data="tableData"
                        @selection-change="handleSelectionChange"
                        v-loading="loading"
                        :header-cell-style="{background:'#FAFAFA'}"
                    >
                        <el-table-column
                            type="selection"
                            width="50px"
                        ></el-table-column>
                        <el-table-column
                            label="状态"
                            prop="status"
                            width="60"
                        >
                            <template v-slot="scope">
                                {{ $utils.getDicValue('SYSTEM_NORMAL_STATUS', scope.row.status).mean }}
                            </template>
                        </el-table-column>
                          <el-table-column
                            label="应用"
                            prop="appCode"
                            width="140"
                        >
                        </el-table-column>
                        <el-table-column
                            label="字典编码"
                            prop="code"
                        ></el-table-column>
                        <el-table-column
                            label="字典名称"
                            prop="name"
                        ></el-table-column>
                        <!-- <el-table-column
                            label="微服务主键标识"
                            prop="microServiceId"
                            width="120%"
                        ></el-table-column>
                        <el-table-column
                            label="字段单位"
                            prop="unit"
                        ></el-table-column> -->
                        <!-- <el-table-column
                            label="创建人"
                            prop="creator"
                        ></el-table-column> -->
                        <el-table-column
                            label="创建时间"
                            prop="createTime"
                        ></el-table-column>
                        <!-- <el-table-column
                            label="修改人"
                            prop="updater"
                        ></el-table-column> -->
                        <el-table-column
                            label="修改时间"
                            prop="updateTime"
                        ></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="toDicItem(scope.row)"
                                    >字典项</el-button
                                >
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="detailInf(scope.row)"
                                    >详情</el-button
                                >
                                <el-button type="text" size="small" @click="dicDel(scope.row)">
                                    <span style="color:#F5222D">
                                        删除
                                    </span>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </TableWrap>
            </el-main>
        </el-container>
        <!-- 抽屉：新增、修改、详情 -->
        <dictionaryDrawer
            ref="dicDrawer"
            :dictionary="tableData"
            :formData="dicDrawer"
            :detail.sync="detail"
            :temp="temp"
            :operateTitle="operateTitle"
            @callback="handleInit"
        />
    </div>
</template>

<script>
import dictionaryDrawer from './dicDrawer'
import tableMixin from '@/mixIn/tableMixIn'
import { reqStytemList } from '@/api/question/safty.js'
import { reqDictionary, reqDictionaryGetById, reqDictionaryDelById } from '@/api/system/dictionary'
export default {
    // 字典管理
    name: 'dictionary',

    mixins: [tableMixin],

    components: {
        dictionaryDrawer // 抽屉
    },

    data () {
        return {
            searchOptions: [
                { type: 'input', key: 'name', label: '字典名称' },
                { type: 'select', key: 'appCode', label: '应用名称', options: [], clearable: true }
            ],
            reqTableData: reqDictionary,
            detail: true, // 详情:取消提交键，详情页输入框禁用
            temp: 0, // 0-新增，1-修改
            search: '', // 搜索内容
            operateTitle: '', // 抽屉标题
            // 抽屉的关联信息
            dicDrawer: {
                code: '', // 编码
                createTime: '', // 创建时间
                creator: '', // 创建人
                extend: null, // 扩展
                microServiceId: '', // 微服务主键标识
                name: '', // 字典名称
                note: '', // 备注
                status: '0', // 状态
                unit: '', // 单位
                updateTime: '', // 修改时间
                updater: '', // 修改人
            },
            status: [],
            SystemOptions: []
        }
    },

    created () {
        this.handleInit()
        reqStytemList().then((res) => {
            // console.log(res)
            res.data.records.forEach(item => {
                const obj = {}
                obj.value = item.code
                obj.label = item.name
                this.SystemOptions.push(obj)
            })
            this.searchOptions[1].options = this.SystemOptions
        })
    },

    methods: {
        handleInit () {
            this.$utils.getDicData('SYSTEM_NORMAL_STATUS')
                .then(res => {
                    this.status = res
                    this.getTableData()
                })
        },

        // 新增
        addDic () {
            this.operateTitle = 'add'
            this.$refs.dicDrawer.drawerVisible = true
            this.detail = false
            this.temp = 0
        },

        // 修改
        updateDic () {
            const length = this.multipleSelection.length
            if (length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请至少选择一个修改项！'
                })
            } else if (length > 1) {
                this.$message({
                    type: 'warning',
                    message: '最多选择一个修改项!'
                })
            } else {
                this.operateTitle = 'update'
                this.$refs.dicDrawer.drawerVisible = true
                this.detail = false // 修改/新增抽屉
                this.temp = 1 // 修改
                const tempData = { ...this.multipleSelection[0] }
                tempData.status = `${tempData.status}`
                this.dicDrawer = tempData
            }
        },

        // 详情
        detailInf (row) {
            this.operateTitle = 'detail'
            reqDictionaryGetById({ id: row.id }).then((result) => {
                this.dicDrawer = result.data
                this.dicDrawer.status = `${this.dicDrawer.status}`
                this.$refs.dicDrawer.drawerVisible = true
            })
            this.detail = true
        },

        // 删除
        dicDel (row) {
            this.$confirm('是否确认删除', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    reqDictionaryDelById({ id: row.id })
                        .then(result => {
                            this.handleInit()
                            this.$message({
                                type: 'success',
                                message: '删除成功'
                            })
                        })
                })
                .catch(() => { })
        },

        // 字典项
        toDicItem (row) {
            const id = row.code
            const name = row.name
            const appCode = row.appCode
            const code = row.code
            this.$router.push({
                name: 'dictionaryItem',
                query: { id: id, name: name, appCode: appCode, dictionaryCode: code }
            })
        }
    }
}
</script>

<style lang="scss" scoped></style>
